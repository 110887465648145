<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="onSubmit">
                <h1>{{ $t('login.login') }}</h1>
                <p class="text-muted">{{ $t('login.login_to') }}</p>
                <CInput v-model.trim="email" :placeholder="$t('login.email')" autocomplete="email" required>
                  <template #prepend-content><CIcon name="cil-user" /></template>
                </CInput>
                <CInput
                  v-model.trim="password"
                  :placeholder="$t('login.password')"
                  type="password"
                  autocomplete="new-password"
                  required
                >
                  <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                </CInput>
                <small v-if="errors === 401" class="form-error">{{ $t('errors.login_error') }}</small>
                <CButton type="submit" color="success" block :disabled="isSubmitting">
                  {{ $t('login.login_btn') }}
                </CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow class="justify-content-center">
                <CCol md="6">
                  <h4 class="text-center">
                    {{ $t('login.sign_up') }}
                  </h4>
                  <CButton color="success" block :to="{ name: 'register' }">
                    {{ $t('login.register_now') }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { LOGIN } from '@/store/action-types/auth';
import { CLEAR_ERRORS } from '@/store/mutation-types/auth';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapState('auth', ['isSubmitting', 'errors']),
  },
  beforeDestroy() {
    if (this.errors) {
      this.CLEAR_ERRORS();
    }
  },
  methods: {
    ...mapActions('auth', [LOGIN]),
    ...mapMutations('auth', [CLEAR_ERRORS]),
    onSubmit() {
      const credentials = {
        username: this.email,
        password: this.password,
      };

      this.LOGIN(credentials).then(() => {
        this.$router.push({ name: 'auth' });
      });
    },
  },
};
</script>
